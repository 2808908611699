import { useEffect, useState } from "react";
import Dialog from "../Dialog";
import { formatDateFromISO } from "../../Utils/date";
import EventDetailsContainer from "./EventDetailsContainer";
import { useCalendarEventContext } from "../../Context/CalendarEventContext";
import { useCalendarEventContextSelector } from "Context/CalendarEventContextSelector";
import FormAanpakkerVoorEenDagContainer from "../Forms/Container/FormAanpakkerVoorEenDagContainer";
import FormHeldenAvondContainer from "../Forms/Container/FormHeldenAvondContainer";
import FormTeamUitjeContainer from "../Forms/Container/FormTeamUitjeContainer";
import FormMaatwerkContainer from "Components/Forms/Container/FormMaatwerkContainer";
import { useToastNotficationContext } from "../../Context/ToastNotificationContext";

export default function EventDetailsDialog({ event, onClose }) {
    const { setToastData } = useToastNotficationContext();
    const [editEvent, setEditEvent] = useState(false);
    const [eventData, setEventData] = useState({});
    const { unavailableDates, calendarEvents, calendarEventsDetails, participants, reload } = useCalendarEventContextSelector();

    const _reload = (data) => {
        if(data?.message) {
            setToastData(data);
        }
        reload();
    }

    const _onClose = (data) => {
        if(data?.message) {
            setToastData(data);
        }
        setEditEvent(false);
    }

    const selectedDay = event && formatDateFromISO(event.date);

    useEffect(() => {
        if(event) {
            let eventData = {};
            switch(event.$$type) {
                case 'teamuitje':
                    {
                        let eventDetails = {};
                        if(calendarEventsDetails[event.$id]) {
                            eventDetails = Object.values(calendarEventsDetails[event.$id])[0] || {};
                        }
                        let unavailableDayParts = (Object.values(calendarEvents[selectedDay]) || []).filter(x => x.$$type == 'teamuitje' && x.$id != event.$id).map((x) => x.daypart);
                        eventData = { mainRequest: event, detailsRequest: eventDetails, unavailableDayParts: unavailableDayParts };
                    }
                break;
                case 'heldenavond':
                    {
                        let eventDetails = {};
                        if(calendarEventsDetails[event.$id]) {
                            eventDetails = Object.values(calendarEventsDetails[event.$id])[0] || {};
                        }
                        eventData = { mainRequest: event, detailsRequest: eventDetails };
                    }
                break;
                case 'aanpakker':
                    {
                        let eventDetails = {};
                        let _participants = [];
                        if(calendarEventsDetails[event.$id]) {
                            eventDetails = Object.values(calendarEventsDetails[event.$id])[0] || {};
                        }
                        if(participants[event.$id]) {
                            _participants = Object.values(participants[event.$id]);
                        }
                        eventData = { mainRequest: event, detailsRequest: eventDetails, participants: _participants };
                        console.log("eventData in aanpakker", eventData)
                    }
                break;
                case 'maatwerk':
                    {
                        let eventDetails = {};
                        if(calendarEventsDetails[event.$id]) {
                            eventDetails = Object.values(calendarEventsDetails[event.$id])[0] || {};
                        }
                        eventData = { mainRequest: event, detailsRequest: eventDetails };
                    }
            }
            setEventData({ type: event.$$type, data: eventData });
        }
    }, [event])


    return <Dialog open={!!event} onClose={onClose}>
        <div className="min-w-96">
            <Dialog open={editEvent} onClose={() => setEditEvent(false)}>
                { eventData.type == 'teamuitje' && <div className="">
                    <FormTeamUitjeContainer selectedDay={selectedDay} reload={_reload} event={eventData} onClose={(data) => _onClose(data)} />
                </div> }
                { eventData.type == 'aanpakker' && <div className="">
                    <FormAanpakkerVoorEenDagContainer selectedDay={selectedDay} reload={_reload} event={eventData} onClose={(data) => _onClose(data)} />
                </div> }
                { eventData.type == 'heldenavond' && <div className="">
                    <FormHeldenAvondContainer selectedDay={selectedDay} reload={_reload} event={eventData} onClose={(data) => _onClose(data)} />
                </div> }
                { eventData.type == 'maatwerk' && <div className="">
                    <FormMaatwerkContainer selectedDay={selectedDay} reload={_reload} event={eventData} onClose={(data) => _onClose(data)} />
                </div> }
            </Dialog> 
            <EventDetailsContainer onEdit={() => setEditEvent(true)} event={eventData} reload={_reload}/>
        </div>
    </Dialog>


}