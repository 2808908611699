export function formatDate(dateStr) {
	const [day, month, year] = dateStr.split('-');
  
	const date = new Date(year, month - 1, day);
	const options = { weekday: 'long', day: 'numeric', month: 'long', year: 'numeric' };
	const formatter = new Intl.DateTimeFormat('nl-NL', options);
	const formattedDate = formatter.format(date);
	return formattedDate.charAt(0).toUpperCase() + formattedDate.slice(1);
}

export function isValidDate(dateStr) {
	let date = getDateObject(dateStr);
	return date instanceof Date && !isNaN(date);
}

export function getDateObject(dateStr) {
	const [day, month, year] = dateStr.split('-');
  
	const date = new Date(year, month - 1, day);
	return date;
}

export function formatDateFromISO(dateString) {
	const date = new Date(dateString);
	const day = date.getDate().toString().padStart(2, '0');
	const month = (date.getMonth() + 1).toString().padStart(2, '0');
	const year = date.getFullYear().toString();
	return `${day}-${month}-${year}`;
}

export function formatDateTimeFromISO(dateString) {
	const date = new Date(dateString);
	const day = date.getDate().toString().padStart(2, '0');
	const month = (date.getMonth() + 1).toString().padStart(2, '0');
	const year = date.getFullYear().toString();
	const hours = date.getHours().toString().padStart(2, '0');
	const minutes = date.getMinutes().toString().padStart(2, '0');
	return `${day}-${month}-${year} ${hours}:${minutes}`;
}


export function formatDateToISO(dateString) {
	console.log("dateString", dateString);
	const [day, month, year] = dateString.split('-');
	const date = new Date(year, month - 1, day);
	return date.toISOString();
}

export function formatTimeForAppWrite(dateString, timeString) {
	const [day, month, year] = dateString.split('-');
	return `${year}-${month}-${day}T${timeString}:00.000+00:00`;
}

export function getTimeFromUTCString(UTCString) {
	return (UTCString || "").replace(/^\d{4}-\d{2}-\d{2}T(\d{2}\:\d{2})\:\d{2}\.\d{3}\+\d{2}\:\d{2}$/, '$1');
}