import { CheckIcon, MinusIcon, PlusIcon } from "@heroicons/react/24/solid";

export default function TextInput({ value = "", label, onChange, placeholder, hasError, className = '' }) {

    let extraClassName = '';
    if(hasError) {

        // console.log("found error on label " + label);

        extraClassName = ' border-red-500';
    }

    return <div className={`flex flex-col gap-2 ${className}`}>
        { label && <label>{label}</label> }
        <input type="text" placeholder={placeholder} value={value || ""} onChange={onChange} className={`flex flex-row px-4 py-3 rounded-xl justify-center items-center box-content border  ${extraClassName} border-gray-300`} />
    </div>

}