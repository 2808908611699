import * as backendApi from '../../api/backend.js';
import { useState, useEffect } from "react";
import EventDetailsPanel from "./EventDetailsPanel";
import api from "../../api/api";
import { Server } from "../../Utils/config";
import { useBackupContext } from 'Context/BackupContext.js';
import { getStandardSignature } from "Utils/email.js";

const typeToCollection = {
    'heldenavond': 'HeldenavondRequest',
    'teamuitje': 'TeamuitjeRequest',
    'aanpakker': 'AanpakkerVoorEenDagRequest',
    'maatwerk': 'CustomEvent'
}

const typeToCollectionDetails = {
    'heldenavond': 'HeldenavondRequestDetails',
    'teamuitje': 'TeamuitjeRequestDetails',
    'aanpakker': 'AanpakkerVoorEenDagRequestDetails',
    'maatwerk': 'CustomEventDetails'
}


export default function EventDetailsContainer({ onEdit, event, reload }) {

    const { backupMode, queueForRestore } = useBackupContext();
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState({
        emailSignature: event?.data?.detailsRequest?.emailSignature || getStandardSignature(event),
        administratorComments: event?.data?.detailsRequest?.administratorComments || "",
    });

    useEffect(() => {
        if(event) {
            setData({
                emailSignature: event?.data?.detailsRequest?.emailSignature || getStandardSignature(event),
                administratorComments: event?.data?.detailsRequest?.administratorComments || "",
            })
        }
    }, [event])

    const onSave = async (skipReload = false, overrideData) => {


        if(backupMode) {

            console.log("event to restore", event);
            queueForRestore(event);
            return console.log("restore backup");
        }


        !skipReload && setLoading(true);
        try {
            await api.updateDocument(Server.databaseID, Server.collections[typeToCollectionDetails[event.type]], event.data.detailsRequest.$id, overrideData || data);
            !skipReload && setLoading(false);
            !skipReload && reload({ message: "Details zijn aangepast", show:true });
        } catch(e) {
            !skipReload && setLoading(false);
            // console.log("error in onState change");
            // console.log(e);
            if(skipReload) {
                throw e;
            }
        }
        return true;
    }


    const onStatusChange = async (status, reason) => {
        setLoading(true);
        try {
            let overrideData = data;
            if(reason) {
                overrideData = { ...data, cancellationComments: reason };
            }
            await onSave(true, overrideData);
            await api.updateDocument(Server.databaseID, Server.collections[typeToCollection[event.type]], event.data.mainRequest.$id, { status: status });
            const { sendEmails } = event.data.detailsRequest
            if (status === 'confirmed' && sendEmails) {
                const requestId = event.data.mainRequest.$id;
                await backendApi.sendConfirmationEmail(event.type, requestId);
            } else if(status === 'rejected' && sendEmails) {
                const requestId = event.data.mainRequest.$id;
                await backendApi.sendRejectionEmail(event.type, requestId);
            }
            setLoading(false)
        } catch(e) {
            setLoading(false)
            console.log("error in onState change");
            console.log(e);
        }
        reload({ message: "Status is aangepast", show:true });
    }


    return <EventDetailsPanel onEdit={onEdit} onStatusChange={onStatusChange} event={event} loading={loading} data={data} setData={setData} backupMode={backupMode} onSave={onSave} />

}
