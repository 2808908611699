import React, { createContext, useContext, useState, useEffect } from 'react';
import { formatDateFromISO } from "../Utils/date";
import { 
    getRandomFakeLastName, 
    getRandomFakeFirstName,
    getFakeOrganizationName,
    getRandomFakeEmail,
    getFakeBirthDate,
    getFakeAddress

} from 'Utils/anonimize';

const BackupContext = createContext();

function aanpakkerAnonimize(aanpakkerDetails) {

   

    return aanpakkerDetails.map((x) => {

        let fakeAddress = getFakeAddress();
        let fakeOrganizationAddress = getFakeAddress();

        return {
            ...x,
            organizationName: getFakeOrganizationName(),
            organizationAddress: `${fakeOrganizationAddress.street} ${fakeOrganizationAddress.number}`,
            organizationPostalCode: fakeOrganizationAddress.zipcode,
            organizationCity: fakeOrganizationAddress.place,
        }
    });
}

function aanpakkerParticipantAnonimize(participants) {
    
    
    
    return participants.map((x) => {

        let fakeFirstName = getRandomFakeFirstName();
        let fakeLastName = getRandomFakeLastName();

        return {
            ...x,
            firstName: fakeFirstName,
            lastName: fakeLastName,
            emailAddress: getRandomFakeEmail(fakeFirstName, fakeLastName),
            phoneNumber: (Math.floor(Math.random() * 1000000000)).toString(),
            birthdate: getFakeBirthDate()
        }
    });

}

function heldenavondAnonimize(heldenAvondDetails) {
    
    
    return heldenAvondDetails.map((x) => {
        let fakeFirstName = getRandomFakeFirstName();
        let fakeLastName = getRandomFakeLastName();

        return {
            ...x,
            firstName: fakeFirstName,
            lastName: fakeLastName,
            emailAddress: getRandomFakeEmail(fakeFirstName, fakeLastName),
            phoneNumber: (Math.floor(Math.random() * 1000000000)).toString(),
            birthdate: getFakeBirthDate()
        }
    });
}

function teamuitjeAnonimize(teamuitjeDetails) {
     
  

    return teamuitjeDetails.map((x) => {

        let fakeFirstName = getRandomFakeFirstName();
        let fakeLastName = getRandomFakeLastName();
        let fakeAddress = getFakeAddress();
        let fakeOrganizationAddress = getFakeAddress();

        return {
            ...x,
            firstName: fakeFirstName,
            lastName: fakeLastName,
            emailAddress: getRandomFakeEmail(fakeFirstName, fakeLastName),
            phoneNumber:  (Math.floor(Math.random() * 1000000000)).toString(),
            organizationName: getFakeOrganizationName(),
            organizationAddress: `${fakeOrganizationAddress.street} ${fakeOrganizationAddress.number}`,
            organizationPostalCode: fakeOrganizationAddress.zipcode,
            organizationCity: fakeOrganizationAddress.place,
        }
    });
}


export default function BackupProvider({ children }) {

    const [backupOptions, setBackupOptions] = useState({ 
        anonimize: false,
        includeUnavailableDates: false,
        includeSettings: false
    });
    const [backup, _setBackup] = useState({});
    const [backupMode, setBackupMode] = useState(false);
    const [restoreQueue, setRestoreQueue] = useState({});

    const queueForRestore = (event) => {
        setRestoreQueue((restoreQueue) => ({ ...restoreQueue, [event.$id]: event }));
    }

    const setBackup = (backup, options) => {

        let optionsToUse = options || backupOptions;

        let _unavailableDates = [];
        let _calendarEvents = [];
        let _calendarEventsDetails = [];
        let _participants = [];
        let _publicSettings = {};

        backup.AanpakkerVoorEenDagRequest.forEach((x) => {
            _calendarEvents.push({ ...x, $$type: "aanpakker" });
        });

        backup.HeldenavondRequest.forEach((x) => {
            _calendarEvents.push({ ...x, $$type: "heldenavond" });
        });

        backup.TeamuitjeRequest.forEach((x) => {
            _calendarEvents.push({ ...x, $$type: "teamuitje" });
        });

        let groupedStructure = _calendarEvents.reduce((col, item) => {
            let events = col[formatDateFromISO(item.date)]
            if(!events) {
                events = {};
            }
            return ({...col, [formatDateFromISO(item.date)]: {...events, [item.$id]:item } })
        }, {});

        console.log('anonymize', optionsToUse.anonimize);

        let aanpakkerVoorEenDagDetails = backup.AanpakkerVoorEenDagRequestDetails;
        if(optionsToUse.anonimize) {
            aanpakkerVoorEenDagDetails = aanpakkerAnonimize(aanpakkerVoorEenDagDetails);
        }

        aanpakkerVoorEenDagDetails.forEach((x) => {
            if(!_calendarEventsDetails[x.requestId]) {
                _calendarEventsDetails[x.requestId] = {};
            }
            _calendarEventsDetails[x.requestId] = {..._calendarEventsDetails[x.requestId], [x.$id]: x};
        });


        let heldenavondRequestDetails = backup.HeldenavondRequestDetails;
        if(optionsToUse.anonimize) {
            heldenavondRequestDetails = heldenavondAnonimize(heldenavondRequestDetails);
        }
        heldenavondRequestDetails.forEach((x) => {
            if(!_calendarEventsDetails[x.requestId]) {
                _calendarEventsDetails[x.requestId] = {};
            }
            _calendarEventsDetails[x.requestId] = {..._calendarEventsDetails[x.requestId], [x.$id]: x};
        });

        let teamuitjeRequestDetails = backup.TeamuitjeRequestDetails;
        if(optionsToUse.anonimize) {
            teamuitjeRequestDetails = teamuitjeAnonimize(teamuitjeRequestDetails);
        }

        teamuitjeRequestDetails.forEach((x) => {
            if(!_calendarEventsDetails[x.requestId]) {
                _calendarEventsDetails[x.requestId] = {};
            }
            _calendarEventsDetails[x.requestId] = {..._calendarEventsDetails[x.requestId], [x.$id]: x};
        });

        let aanpakkerVoorEenDagParticipants = backup.AanpakkerVoorEenDagRequestParticipant;
        if(optionsToUse.anonimize) {
            aanpakkerVoorEenDagParticipants = aanpakkerParticipantAnonimize(aanpakkerVoorEenDagParticipants);
        }

        aanpakkerVoorEenDagParticipants.forEach((x) => {
            if(!_participants[x.requestId]) {
                _participants[x.requestId] = {};
            }
            _participants[x.requestId] = {..._participants[x.requestId], [x.$id]: x};
        });

        (backup.UnavailableDate || []).forEach((x) => {
           _unavailableDates.push({...x, date: formatDateFromISO(x.date)}); 
        });

        backup.PublicSettings.forEach((x) => {
            _publicSettings[x.$id] = x;
        });
        


        _setBackup({
            calendarEvents: groupedStructure,
            participants: _participants,
            calendarEventsDetails: _calendarEventsDetails,
            unavailableDates: _unavailableDates,
            publicSettings: _publicSettings,
            reload: () => {}
        });
        setBackupMode(true);
    }

    console.log("backup context value", backup);

    return <BackupContext.Provider value={{ 
        backupOptions, setBackupOptions,
        backup, setBackup, backupMode, 
        setBackupMode, restoreQueue, 
        queueForRestore, setRestoreQueue }}>
        {children}
    </BackupContext.Provider>
}

export const useBackupContext = () => {
    return useContext(BackupContext);
}

