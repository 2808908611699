
import api from "api/api";
import { Server } from "Utils/config";
import { getTimeFromUTCString } from "Utils/date";
import { getData } from "Utils/form";
import { cleanProperties } from "Utils/object";

const maatwerkFormFields = [
	'date',
	'startTime', 
    'endTime',
	'amountOfParticipants', 
	'lunch',
	'primaryContactFirstName', 
	'primaryContactLastName', 
	'primaryContactEmailAddress', 
	'primaryContactPhoneNumber', 
    'secondaryContactFirstName',
    'secondaryContactLastName',
    'secondaryContactEmailAddress',
    'secondaryContactPhoneNumber',
	'description',
    "status",
	'emailSignature',
	'sendEmails'
]

export async function updateMaatwerkAction(maatwerkFormData, event) {
	let mainRequestToSave = { ...getData(maatwerkFormData, maatwerkFormFields, [], ['date', 'status']) };
	delete mainRequestToSave.$$type;
	await api.updateDocument(Server.databaseID, Server.collections['CustomEvent'], event.data.mainRequest.$id, mainRequestToSave );
	let detailsRequestToSave = { ...getData(maatwerkFormData, maatwerkFormFields, ['date', 'status']) };
	if(event.data.detailsRequest.$id) {
		console.log("detailsRequestToSave", detailsRequestToSave);
		await api.updateDocument(Server.databaseID, Server.collections['CustomEventDetails'], event.data.detailsRequest.$id, detailsRequestToSave);
	} else {
		console.log("detailsRequestToSave (creating)", detailsRequestToSave);
		detailsRequestToSave.requestId = mainRequestToSave.$id;
		await api.createDocument(Server.databaseID, Server.collections['CustomEventDetails'], detailsRequestToSave);
	}
	return true;
}

export async function addMaatwerkAction(maatwerkFormData, selectedDay) {
	let mainRequestToSave = { ...getData(maatwerkFormData, maatwerkFormFields, [], ['date', 'status']) };
	mainRequestToSave.date = selectedDay;
	mainRequestToSave.status = mainRequestToSave.status ?? 'pending';
	let request = await api.createDocument(Server.databaseID, Server.collections['CustomEvent'], mainRequestToSave);
	let requestId = request.$id;
	let detailsRequestToSave = { ...getData(maatwerkFormData, maatwerkFormFields, ['date', 'status']) };
	let createData = { ...detailsRequestToSave, requestId: requestId };
	await api.createDocument(Server.databaseID, Server.collections['CustomEventDetails'], createData);
	return true;
}

// export async function restoreTeamuitjeBackup(event, details) {
// 	let mainEvent = cleanProperties(event);
// 	mainEvent.$id = event.$id;
// 	let eventDetails = cleanProperties(details);
// 	eventDetails.$id = details.$id;
// 	let request = await api.createDocument(Server.databaseID, Server.collections['TeamuitjeRequest'], mainEvent);
// 	eventDetails.requestId = request.$id;
// 	await api.createDocument(Server.databaseID, Server.collections['TeamuitjeRequestDetails'], eventDetails);
// 	return 2;
// }



export function createMaatwerkFormData(event) {
	if(event && event.data && event.data.mainRequest) {
		let maatwerkFormData = { ...getData(event.data.mainRequest, maatwerkFormFields), ...getData(event.data.detailsRequest, maatwerkFormFields) };
		
		maatwerkFormData.startTimeString = getTimeFromUTCString(maatwerkFormData.startTime);
		maatwerkFormData.endTimeString = getTimeFromUTCString(maatwerkFormData.endTime);
		
		return maatwerkFormData;
	}
	return { amountOfParticipants: 10, lunch: false, sendEmails: false, emailSignature: "" };

}

const isValidTime = (time) => {


	let match = time.match(/^\d{2}\:\d{2}$/);
	return match;

}

export function validateMaatwerkForm(maatwerkFormData) {
	let errors = {};

	



	errors['startTimeString'] = !maatwerkFormData.startTimeString || !isValidTime(maatwerkFormData.startTimeString);
    errors['endTimeString'] = !maatwerkFormData.endTimeString || !isValidTime(maatwerkFormData.endTimeString);
	errors['primaryContactFirstName'] = !maatwerkFormData.primaryContactFirstName;
	errors['primaryContactLastName'] = !maatwerkFormData.primaryContactLastName;
	errors['primaryContactEmailAddress'] = !(maatwerkFormData.primaryContactEmailAddress && maatwerkFormData.primaryContactEmailAddress.match(/[a-z0-9._%+\-]+@[a-z0-9.\-]+\.[a-z]{2,}$/));
    errors['secondaryContactEmailAddress'] = (maatwerkFormData.secondaryContactEmailAddress && !maatwerkFormData.secondaryContactEmailAddress.match(/[a-z0-9._%+\-]+@[a-z0-9.\-]+\.[a-z]{2,}$/));
	errors['primaryContactPhoneNumber'] = !maatwerkFormData.primaryContactPhoneNumber;
	let errorsFound = Object.keys(errors).length > 0;
	return { errors, errorsFound };
}
