import { useEffect, useState } from 'react';
import { useBackupContext } from 'Context/BackupContext';
import { createMaatwerkFormData, validateMaatwerkForm, addMaatwerkAction, updateMaatwerkAction } from '../Actions/Maatwerk';
import FormMaatwerkPanel from '../FormMaatwerkPanel';
import { getStandardSignature } from 'Utils/email';
import { formatTimeForAppWrite, getDateObject } from 'Utils/date';

export default function FormMaatwerkContainer({ selectedDay, event, reload, onClose }) {

    const { backupMode } = useBackupContext();
    const [maatwerkFormData, setMaatwerkFormData] = useState({ 
        amountOfParticipants: 10, lunch: false 
    });
    const [maatwerkErrors, setMaatwerkErrors] = useState({});
    const [loading, setLoading] = useState(false);


    // console.log("errors in maatwerk", maatwerkErrors);

    useEffect(() => {
        setMaatwerkFormData(createMaatwerkFormData(event));
    }, [event])

    const updateMaatwerk = async (maatwerkFormData) => {
		setLoading(true);
		try {
            updateMaatwerkAction(maatwerkFormData, event);
			setLoading(false);
			onClose({ message: "Maatwerk is aangepast" });
			reload();
		} catch (err) {
			setLoading(false);
			console.log(err);
		}
	}

    const addMaatwerk = async (maatwerkFormData) => {
		setLoading(true);
		try {
			addMaatwerkAction(maatwerkFormData, selectedDay);
            setLoading(false);
			onClose({ message: "Maatwerk is toegevoegd" });
			reload();
		} catch (err) {
			setLoading(false);
			console.log(err);
		}
	}

    const validate = (maatwerkFormData) => {
        let { errors } = validateMaatwerkForm(maatwerkFormData);
        setMaatwerkErrors(errors);
        return Object.values(errors).some((error) => error);
    }

    const saveMaatwerk = async () => {

        if(backupMode) {
            console.log("restore backup");
            return;
        }

        if(!validate(maatwerkFormData)) {

            maatwerkFormData.startTime = formatTimeForAppWrite(selectedDay, maatwerkFormData.startTimeString);
            maatwerkFormData.endTime = formatTimeForAppWrite(selectedDay, maatwerkFormData.endTimeString);

            if(event?.data?.mainRequest?.$id) {
                return updateMaatwerk(maatwerkFormData);
            } else {
                return addMaatwerk(maatwerkFormData);
            }
        } 
    }

    const setMaatwerk = (maatwerk) => {
        if(
            maatwerk.startTime != maatwerkFormData.startTime ||
            maatwerk.endTime != maatwerkFormData.endTime
        ) {
            
        }
        setMaatwerkFormData(maatwerk);
    }

    // console.log("render maatwerk formdata", maatwerkFormData);
    // console.log("selectedDay", selectedDay);

    return <FormMaatwerkPanel 
        backupMode={backupMode}
        maatwerk={maatwerkFormData}
        setMaatwerk={setMaatwerk}
        errors={maatwerkErrors}
        onSave={saveMaatwerk}
        loading={loading} />
}