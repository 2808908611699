import { useEffect, useState } from "react";
import Calendar from "../Components/Calendar";
import DayCard from "../Components/DayCard";
import Dialog from "../Components/Dialog";
import { useCalendarEventContext } from "../Context/CalendarEventContext";
import { useBackupContext } from "Context/BackupContext";
import FormHeldenAvondContainer from '../Components/Forms/Container/FormHeldenAvondContainer';
import DayActionsPanel from "../Components/DayActionsPanel";
import FormTeamUitjeContainer from "../Components/Forms/Container/FormTeamUitjeContainer";
import FormAanpakkerVoorEenDagContainer from "../Components/Forms/Container/FormAanpakkerVoorEenDagContainer";
import FormMaatwerkContainer from "Components/Forms/Container/FormMaatwerkContainer";
import { useToastNotficationContext } from "../Context/ToastNotificationContext";
import EventDetailsDialog from "../Components/EventDetailsDialog";
import { ChevronRightIcon } from "@heroicons/react/20/solid";
import { CalendarAvailabilityButton } from "../Components/CalendarAvailabilityDialog";
import { useCalendarEventContextSelector } from "../Context/CalendarEventContextSelector";
import { useCalendar } from "../Context/CalendarContext";

export const calendarLabels = {
	'aanpakker': 'Aanpakker voor een dag',
	'heldenavond': 'Heldenavond',
	'teamuitje': 'Teamuitje',
	'maatwerk': 'Maatwerk event'
}

export const statusClassName = {
	'confirmed': 'bg-green-500',
	'pending': 'bg-orange-500',
	'rejected': 'bg-red-500',
}



export default function AdminCalendar() {

	const { selectedMonth, selectedYear } = useCalendar();
	const { backupMode, queueForRestore  } = useBackupContext();

	const { unavailableDates, calendarEvents, calendarEventsDetails, participants, reload } = useCalendarEventContextSelector();
	const { setToastData } = useToastNotficationContext();

	const [selectedDay, setSelectedDay] = useState(null);
	const [selectedEvent, setSelectedEvent] = useState(null);
	const [editableEvent, setEditableEvent] = useState(null);
	// const [eventsInMonth, setEventsInMonth] = useState({}); // only use is for backup

	useEffect(() => {

		if(calendarEvents && editableEvent) {
			let event = calendarEvents[selectedDay][editableEvent.$id];
			setEditableEvent(event);
		}

	}, [calendarEvents]);

	const onRestoreWholeMonth = () => {
		let events = {};
		Object.keys(calendarEvents).map((date) => {
			if(date.split('-')[1] == selectedMonth && date.split('-')[2] == selectedYear) {
				events = {...events,...calendarEvents[date]};
			}
		});



		Object.values(events).forEach(event => queueForRestore(event));
	}



	const selectDay = (date) => {
		setSelectedDay((prevDate) => prevDate == date ? null : date)
	}

	let dayCards = {};

	// let _setEventsInMonth = (() => {

	// 	let events = {};
	// 	Object.keys(calendarEvents).map((date) => {
	// 		if(date.split('-')[1] == selectedMonth && date.split('-')[2] == selectedYear) {
	// 			events[date] = calendarEvents[date];
	// 		}
	// 	});
	// 	return events;

	// })()

	// Als een datum niet beschikbaar is
	if (unavailableDates) { 
        Object.values(unavailableDates).map(x => {
            if(
				!x.teamuitjeMorningUnavailable ||
				!x.teamuitjeAfternoonUnavailable ||
				!x.heldenavondUnavailable ||
				x.aanpakkerMaximumAmountOfParticipants > 0
			) {
				return;
			}
			
			dayCards[x.date] = {
                dayLabelClass: 'text-black',
                clickable: false,
                component: <DayCard>
					<div className="p-2 bg-cal-lighter-gray text-black w-full h-full flex  items-center">
						<p className="invisible lg:visible text-left">Dag onbeschikbaar</p>
					</div>
				</DayCard>
            } 
        }) 
    }

	Object.keys(calendarEvents).map((date) => {
		if(dayCards[date]) return;
		let events = Object.values(calendarEvents[date]).filter(x => x.status != 'rejected').map((event) => {
			let eventDetails = calendarEventsDetails[event.$id];
			let status = "pending";
			switch(event.status) {
				case 'confirmed':
					status = "confirmed";
				break;
				case 'pending':
					status = "pending";
				break;
				case 'rejected':
					status = "rejected";
				break;
			}
			let daypart = event.daypart == "morning" ? "ochtend" : "middag";
			daypart = event.daypart ? `(${daypart})` : '';
			return <li key={event.$id} className="text-xs flex flex-row gap-2 items-center">
				<div className={`w-2 h-2 aspect-square ${statusClassName[status]}`}></div>
				{calendarLabels[event.$$type]} {daypart}
			</li>
		});

		let eventsPending = Object.values(calendarEvents[date]).filter(x => x.status == 'pending').length;
		let pendingObject = null;
		if(eventsPending > 0) {
			pendingObject = <div className="absolute bottom-1 right-1 bg-orange-500 rounded-full text-white px-2 font-bold leading-0">{eventsPending}</div>
		
		}

		if(events.length > 4) {
			let eventsLength = events.length;
			events = events.slice(0, 3);
			events.push(<li key="more" className="text-xs flex flex-row gap-2 items-center">
				{`+${eventsLength- 3} meer`}
			</li>)
		}

		dayCards[date] = {
			dayLabelClass: 'text-black',
			component: <DayCard>
				<div className="w-full h-full flex flex-col justify-center p-2 relative">
					<ul className="flex flex-col text-left gap-1">
						{events}
					</ul>
					{pendingObject}
				</div>
			</DayCard>
		}
	});

	const addEvent = (type, unavailableDayParts) => {
		setSelectedEvent({ type, data: { unavailableDayParts: unavailableDayParts } });
	}

	const editEvent = (event) => {
		setEditableEvent(event);
	}

	const onClose = (data) => {
		setSelectedEvent(null);
		setEditableEvent(null);
		if(data?.message) {
			setToastData({ show: true, message: data.message, type: data.type || 'success' })
		}
	}

	return <div className="lg:w-[70vw] mx-auto lg:p-5">
		
		<Dialog open={!!selectedDay} onClose={() => {
			setSelectedDay(null) 
		}}>
			<EventDetailsDialog event={editableEvent} onClose={onClose} />
			<div className="min-w-96">
				<Dialog open={!!(selectedEvent && selectedEvent.type == "teamuitje")} onClose={onClose}>
					<div className="">
						<FormTeamUitjeContainer selectedDay={selectedDay} reload={reload} event={selectedEvent} onClose={onClose} />
					</div>
				</Dialog>
				<Dialog open={!!(selectedEvent && selectedEvent.type == "aanpakker")} onClose={onClose}>
					<div className="">
						<FormAanpakkerVoorEenDagContainer selectedDay={selectedDay} reload={reload} event={selectedEvent} onClose={onClose} />
					</div>
				</Dialog>
				<Dialog open={!!(selectedEvent && selectedEvent.type == "heldenavond")} onClose={onClose}>
					<div className="">
						<FormHeldenAvondContainer selectedDay={selectedDay} reload={reload} event={selectedEvent} onClose={onClose} />
					</div>
				</Dialog>
				<Dialog open={!!(selectedEvent && selectedEvent.type == "maatwerk")} onClose={onClose}>
					<div className="">
						<FormMaatwerkContainer selectedDay={selectedDay} reload={reload} event={selectedEvent} onClose={onClose} />
					</div>
				</Dialog>
				<DayActionsPanel onEdit={editEvent} selectedDay={selectedDay} dayEvents={calendarEvents[selectedDay]} onAdd={addEvent} />
			</div>
		</Dialog>

		<h1 className="text-2xl font-bold">Kalender overzicht</h1>
		<div className="flex flex-col lg:flex-row gap-2 p-2 py-6 justify-between">
			<div className="flex flex-row gap-2">
				<div className="flex flex-row gap-2">
					<div className="w-6 h-6 aspect-square border border-black  bg-green-500"></div>
					<div className="">Ingepland</div>
				</div>
				<div className="flex flex-row gap-2">
					<div className="w-6 h-6 aspect-square border border-black bg-orange-500"></div>
					<div className="">Pending</div>
				</div>
			</div>
			{ backupMode && <div className="">
				<button onClick={onRestoreWholeMonth} className="bg-green-500 text-white px-4 py-2 rounded-md">Herstel gehele maand</button>
			</div> }
			<div className="flex flex-row gap-2">
				<div className="flex flex-row gap-2">
					<div className="">Beschikbaarheid kalender: </div>
					<div className="">
						<CalendarAvailabilityButton />
						
					</div>
				</div>
			</div>
		</div>
		<Calendar onDayClick={(date) => selectDay(date)} dayCards={dayCards} />
	</div>
}







			// let response = await createTeamUitjeRequest({...teamuitje, date: convertToISODate(selectedDay), daypart: teamuitje.daypart, status: "pending"})
			// console.log("response", response);
			// console.log(response.data);
			// setTeamUitjeErrors({});
